import React, { FunctionComponent } from 'react'
import { Elements, StripeProvider } from 'react-stripe-elements'
import { makeStyles } from '@material-ui/core'
import UpgradeForm from './UpgradeForm'
import Section from '../../../shared/components/Section'

const stripeKey = process.env.REACT_APP_STRIPE_API_KEY || 'missing'

interface Props {
  record: {
    tier: string
  }
}

const useStyles = makeStyles((theme) => ({
  section: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(3),
    color: '#535353',
    '& a': {
      textDecoration: 'none',
      color: '#3f51b5',
    },
    '& input': {
      backgroundColor: '#c8c8c8',
    },
  },
  heading: {
    textTransform: 'uppercase',
  },
  list: {
    paddingLeft: theme.spacing(1.8),
  },
}))

const Upgrade: FunctionComponent<Props> = (props) => {
  const {
    record: { tier },
  } = props

  const classes = useStyles()

  if (tier === 'FREE') {
    return (
      <StripeProvider apiKey={stripeKey}>
        <Section className={classes.section}>
          <div className="upgradeMarketing">
            <h4 className={classes.heading}>Upgrade</h4>
            <p>
              <strong>You are currently on the free plan.</strong>
            </p>
            <p>
              <strong>Standard plan features include</strong>
            </p>
            <ul className={classes.list}>
              <li>Larger replica sizes</li>
              <li>Multiple replicas</li>
              <li>Team access controls</li>
              <li>No inactivity checks</li>
              <li>Production-grade postgres</li>
            </ul>
            <p>
              For more details about the standard tier, see the{' '}
              <a href="https://www.gigalixir.com/docs/tiers-pricing#tiers">
                Tier Comparison
              </a>
              <br />
              The standard plan costs $10/mo per 200MB of memory. CPU, power,
              &amp; bandwidth are free. Pro-rated to the second.{' '}
              <a href="https://www.gigalixir.com/docs/tiers-pricing#pricing-details">
                Pricing Details
              </a>
              <br />
              To estimate costs, try our{' '}
              <a href="https://www.gigalixir.com/pricing">Cost Estimator</a>
            </p>
            <p>Please enter your credit card information below to upgrade.</p>
          </div>

          <Elements>
            {/* stripe parameter is injected automatically by StripeProvider, but 
                typescript complains about it if I don't put something here */}
            <UpgradeForm />
          </Elements>
        </Section>
      </StripeProvider>
    )
  }
  return <span />
}

export default Upgrade
