import classnames from 'classnames'
import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { MenuItemLink } from 'react-admin'
import { getResources, useLogout } from 'ra-core'
import { makeStyles } from '@material-ui/core'
import AccountCircle from '@material-ui/icons/AccountCircle'
import AppsIcon from '@material-ui/icons/Dns'
import HelpIcon from '@material-ui/icons/Email'
import ExitIcon from '@material-ui/icons/ExitToApp'
import DocsIcon from '@material-ui/icons/Help'

interface Props {
  classes?: Record<string, string>
  className?: string
  [key: string]: any
}

const useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  menuItem: {
    height: theme.spacing(5),
    color: '#000',
    padding: theme.spacing(0, 3),
    '& > div': {
      minWidth: 'unset',
      paddingRight: theme.spacing(3),
    },
  },
  activeMenuItem: {
    backgroundColor: theme.palette.background.paper,
  },
  inactive: {
    // color: 'rgba(0,0,0,0.54)',
  },
}))

const Menu: FunctionComponent<Props> = ({
  classes: styles,
  className,
  dense,
  onMenuClick,
  resources,
  // we handle this in sessions.ts. we do not need to vary the logout
  // destination, so we just ignore it here
  // redirectTo,
  // this is here so that it isn't in `rest` and does not get passed into the div which errors
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  hasDashboard,
  ...rest
}) => {
  const logout = useLogout()
  const classes = {
    ...useStyles(),
    ...styles,
  }

  return (
    <div className={classnames(classes.main, className)} {...rest}>
      {resources
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .filter((r: any) => r.hasList)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .map((resource: any) => (
          <MenuItemLink
            classes={{
              root: classes.menuItem,
              active: classes.activeMenuItem,
            }}
            key={resource.name}
            to={`/${resource.name}`}
            primaryText={`${resource.name
              .charAt(0)
              .toUpperCase()}${resource.name.slice(1)}`}
            leftIcon={resource.icon ? <resource.icon /> : <AppsIcon />}
            onClick={onMenuClick}
            dense={dense}
          />
        ))}
      <MenuItemLink
        classes={{
          root: classes.menuItem,
          active: classes.activeMenuItem,
        }}
        key="account"
        to="/account"
        primaryText="Account"
        leftIcon={<AccountCircle />}
        onClick={onMenuClick}
        dense={dense}
      />
      <MenuItemLink
        classes={{
          root: classnames(classes.menuItem, classes.inactive),
        }}
        key="docs"
        to="#"
        primaryText="Docs"
        leftIcon={<DocsIcon />}
        onClick={() => {
          const a = document.createElement('a')
          a.href = 'https://gigalixir.com/docs/'
          a.target = '_blank'
          a.rel = 'noopener noreferrer'
          a.click()
        }}
        dense={dense}
      />
      <MenuItemLink
        classes={{
          root: classnames(classes.menuItem, classes.inactive),
        }}
        key="help"
        to="#"
        primaryText="Help"
        leftIcon={<HelpIcon />}
        onClick={() => {
          window.location.href = 'mailto:help@gigalixir.com'
        }}
        dense={dense}
      />
      {/* do not redirect until ater logout is complete or you get loops since //
      the login page checks for auth status and redirects to /apps */}
      <MenuItemLink
        classes={{
          root: classnames(classes.menuItem, classes.inactive),
        }}
        key="logout"
        to="#"
        primaryText="Logout"
        leftIcon={<ExitIcon />}
        onClick={logout}
        dense={dense}
      />
    </div>
  )
}

Menu.defaultProps = {
  onMenuClick: () => null,
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapStateToProps = (state: any) => ({
  open: state.admin.ui.sidebarOpen,
  resources: getResources(state),
  pathname: state.router.location.pathname, // used to force redraw on navigation
})

const enhance = compose(
  connect(
    mapStateToProps,
    {}, // Avoid connect passing dispatch in props,
    null,
    {
      areStatePropsEqual: (prev, next) =>
        prev.resources.every(
          (value, index) => value === next.resources[index] // shallow compare resources
        ) &&
        prev.pathname === next.pathname &&
        prev.open === next.open,
    }
  )
)

export default enhance(Menu)
