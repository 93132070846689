import React, { FunctionComponent } from 'react'
import { makeStyles } from '@material-ui/core'
import Section from '../../../shared/components/Section'

interface Props {
  appId: string
  email: string
  apiKey: string
}

const useStyles = makeStyles({
  section: {
    color: 'rgba(0,0,0,0.7)',
    '&:first-child': {
      margin: 0,
    },
    '& > p': {
      marginBottom: 5,
    },
    '& > h4': {
      color: 'black',
    },
  },
})

const Setup: FunctionComponent<Props> = (props) => {
  const classes = useStyles()
  return (
    <>
      <Section className={classes.section}>
        <h4>Getting Started Guide</h4>
        <p>
          To learn how to deploy, please follow our{' '}
          <a href="https://www.gigalixir.com/docs/getting-started-guide/">
            getting started guide
          </a>{' '}
          or watch{' '}
          <a href="https://www.gigalixir.com/docs/">
            our screencast
          </a>
          . If you run into issues, please{' '}
          <a href="mailto:help@gigalixir.com">contact us</a>.
        </p>
      </Section>
    </>
  )
}

export default Setup
