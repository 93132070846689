import React, { FunctionComponent } from 'react'
import { useQueryWithStore } from 'ra-core'
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core'
import CreateDialog from './CreateDialog'
import DeleteDialog from './DeleteDialog'
import { Domain } from './actions'
import Loading from '../../../shared/components/Loading'
import ErrorComponent from '../../../shared/components/ErrorComponent'
import NoData from '../../../shared/components/NoData'
import TableWrapper from '../../../shared/components/TableWrapper'
import DialogButton from '../../../shared/components/DialogButton'

interface Props {
  appId: string
}

const Domains: FunctionComponent<Props> = (props) => {
  const { appId } = props

  const {
    data: domains,
    loading,
    error,
  }: {
    data?: Domain[]
    loading: boolean
    error?: Error
  } = useQueryWithStore({
    type: 'GET_LIST_BY_ID',
    resource: 'domains',
    payload: {
      id: appId,
    },
  })

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <ErrorComponent>{error.message}</ErrorComponent>
  }

  const domainDialog = (
    <DialogButton label="Add">
      {(close) => (
        <CreateDialog
          onSuccess={() => close()}
          onCancel={() => close()}
          appId={appId}
        />
      )}
    </DialogButton>
  )

  if (!(domains && domains.length > 0)) {
    return (
      <NoData
        note={
          <>
            <span>If you have a custom domain name, add it here.</span>
            <a
              href="https://www.gigalixir.com/docs/domain#how-to-set-up-a-custom-domain"
              target="_blank"
              rel="noopener noreferrer"
            >
              Read More
            </a>
          </>
        }
        message="No domains yet"
      >
        {domainDialog}
      </NoData>
    )
  }

  return (
    <>
      {domainDialog}
      <TableWrapper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Fully Qualified Domain Name</TableCell>
              <TableCell>CNAME</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {domains.map((domain) => (
              <TableRow key={domain.domain}>
                <TableCell>{domain.domain}</TableCell>
                <TableCell>{domain.cname}</TableCell>
                <TableCell align="right">
                  <DialogButton label="Delete">
                    {(close) => (
                      <DeleteDialog
                        onSuccess={() => close()}
                        onCancel={() => close()}
                        appId={appId}
                        fqdn={domain.domain}
                      />
                    )}
                  </DialogButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableWrapper>
    </>
  )
}

export default Domains
