import React, { FunctionComponent } from 'react'
import { useQueryWithStore } from 'ra-core'
import {
  makeStyles,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core'
import ConfigurationValue from './ConfigurationValue'
import UpsertDialog from './UpsertDialog'
import DeleteDialog from './DeleteDialog'
import { Configuration } from './actions'
import Loading from '../../../shared/components/Loading'
import ErrorComponent from '../../../shared/components/ErrorComponent'
import NoData from '../../../shared/components/NoData'
import TableWrapper from '../../../shared/components/TableWrapper'
import DialogButton from '../../../shared/components/DialogButton'

interface Props {
  id: string
}

const useStyles = makeStyles((theme) => ({
  name: {
    width: 300,
  },
  edit: {
    width: 100,
  },
  delete: {
    width: 100,
  },
}))

const Configurations: FunctionComponent<Props> = (props) => {
  const { id } = props
  const {
    data: configurations,
    loading,
    error,
  }: {
    data?: Configuration[]
    loading: boolean
    error?: Error
  } = useQueryWithStore({
    type: 'GET_LIST_BY_ID',
    resource: 'configurations',
    payload: {
      id,
    },
  })

  const classes = useStyles()

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <ErrorComponent>{error.message}</ErrorComponent>
  }

  const createDialog = (
    <DialogButton label="Add">
      {(close) => (
        <UpsertDialog
          onSuccess={() => close()}
          onCancel={() => close()}
          appId={id}
        />
      )}
    </DialogButton>
  )

  if (!(configurations && configurations.length > 0)) {
    return (
      <NoData
        note={
          <>
            <span>
              Use environment variables to store secrets and configure your app.
            </span>
            <a
              href="https://www.gigalixir.com/docs/config#environment-variables-and-secrets"
              target="_blank"
              rel="noopener noreferrer"
            >
              Read More
            </a>
          </>
        }
        message="No environment variables yet."
      >
        {createDialog}
      </NoData>
    )
  }

  return (
    <>
      {createDialog}
      <TableWrapper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.name}>Name</TableCell>
              <TableCell>Value</TableCell>
              <TableCell className={classes.edit} />
              <TableCell className={classes.delete} />
            </TableRow>
          </TableHead>
          <TableBody>
            {configurations.map((configuration) => (
              <TableRow key={configuration.name}>
                <TableCell className={classes.name}>
                  {configuration.name}
                </TableCell>
                <TableCell>
                  <ConfigurationValue>{configuration.value}</ConfigurationValue>
                </TableCell>
                <TableCell align="right" className={classes.edit}>
                  <DialogButton label="Edit">
                    {(close) => (
                      <UpsertDialog
                        onSuccess={() => close()}
                        onCancel={() => close()}
                        appId={id}
                        initialValues={configuration}
                      />
                    )}
                  </DialogButton>
                </TableCell>
                <TableCell align="right" className={classes.delete}>
                  <DialogButton label="Delete">
                    {(close) => (
                      <DeleteDialog
                        onSuccess={() => close()}
                        onCancel={() => close()}
                        appId={id}
                        configurationName={configuration.name}
                      />
                    )}
                  </DialogButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableWrapper>
    </>
  )
}

export default Configurations
