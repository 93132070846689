import React, { FunctionComponent, useEffect } from 'react'
import { BrowserRouter, Route } from 'react-router-dom'
import { Admin, Resource } from 'react-admin'
import TestPage from './TestPage'
import theme from './theme'
import Layout from './shared/components/Layout/LayoutWrapper'
import dataProvider from './shared/dataProvider/index'
import AppList from './modules/Apps'
import AppShow from './modules/Apps/App'
import ProfileShow from './modules/Account'
import {
  authProvider,
  Login,
  OauthCli,
  ResendConfirmation,
  ResetPassword,
  SetPassword,
  Success,
} from './modules/Auth'
import NotifyPage from './modules/Notify'
import analyticsSaga from './utils/customSagas/analyticsSaga'

// Redirect component for /register
const RegisterRedirect = () => {
  useEffect(() => {
    window.location.href = "https://signup.gigalixir.com";
  }, []);

  return <p>Redirecting to signup...</p>;
};

const customRoutes = [
  <Route path="/apps/:id/:tab/:subtab?" component={AppShow} />,
  <Route path="/apps/:id/:tab?" component={AppShow} />,
  <Route path="/account/:tab?" component={ProfileShow} />,
  <Route exact path="/test" component={TestPage} noLayout />,
  <Route exact path="/register" component={RegisterRedirect} noLayout />,  // Updated line
  <Route exact path="/oauth/cli" component={OauthCli} noLayout />,
  <Route exact path="/notify" component={NotifyPage} noLayout />,
  <Route exact path="/password/reset" component={ResetPassword} noLayout />,
  <Route exact path="/password/set" component={SetPassword} noLayout />,
  <Route
    exact
    path="/confirmation/resend"
    component={ResendConfirmation}
    noLayout
  />,
  <Route exact path="/success" component={Success} noLayout />,
]

const App: FunctionComponent = () => (
  <BrowserRouter>
    <Admin
      title=""
      theme={theme}
      customSagas={[analyticsSaga]}
      customRoutes={customRoutes}
      layout={Layout}
      loginPage={Login}
      authProvider={authProvider}
      dataProvider={dataProvider}
    >
      <Resource name="apps" list={AppList} />
      <Resource name="profile" />
      <Resource name="databases" />
      <Resource name="payment_methods" />
      <Resource name="invoices" />
      <Resource name="permissions" />
    </Admin>
  </BrowserRouter>
)

export default App
